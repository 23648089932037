<template>
    <div class="position-relative has-tool-menu">
        <div class="tool-menu">
            <Breadcrumb />

            <div class="buttons">
                <ButtonAreas :entities="selected" />
            </div>
        </div>

        <b-card no-body>
            <div slot="header">
                <i class="fa fa-user-tie"></i> Produkter
                <div class="card-header-actions"></div>
            </div>
            <DataTable ref="dataTable" v-if="fields" url="/api/module/pricing/products" :fields="fields" name="products" :filter="true" @row-click="rowClick" cache-search="pricing">
                <template slot="name" slot-scope="{props}">
                    <b-link :to="'/pricing/' + props.row.Id">
                        {{props.row.name}}
                    </b-link>
                </template>

                <template slot="CreateDate" slot-scope="{props}">
                    {{$moment(props.row.CreateDate).format("DD-MM-YYYY HH:mm")}}
                </template>

                <template slot="edit" slot-scope="{props}">
                    <b-button append :to="props.row.Id" variant="primary"><i class="fa fa-eye"></i> Se mere</b-button>
                </template>
            </DataTable>
        </b-card>
    </div>
</template>

<script>
    export default {
        data: () => ({
            fields: [
                {
                    "name": "productId",
                    "type": "readonly",
                    "label": "Produkt Id",
                    "value": null,
                    "filter": true,
                    "visible": true,
                    "sort": true,
                    "class": null,
                    "values": []
                },
                {
                    label: "Navn",
                    name: "name",
                    filter: true,
                    type: "text",
                    sort: true,
                    class: null,
                    value: null,
                    visible: true
                },
                {
                    label: "Navn 2",
                    name: "name2",
                    filter: true,
                    type: "text",
                    sort: true,
                    class: null,
                    value: null,
                    visible: true
                },
                {
                    "name": "brand",
                    "type": "text",
                    "label": "Producent",
                    "value": null,
                    "filter": true,
                    "visible": true,
                    "sort": false,
                    "class": null,
                    "values": []
                },
                {
                    label: "Varenummer",
                    name: "sku",
                    filter: true,
                    type: "text",
                    sort: true,
                    class: null,
                    value: null,
                    visible: true
                },
                {
                    label: "Prisstyring",
                    name: "pricing",
                    filter: true,
                    type: "select",
                    sort: true,
                    class: null,
                    value: null,
                    visible: true,
                    "values": [
                        "Ja",
                        "Nej"
                    ]
                },
                {
                    label: "Varianttype",
                    name: "variantType",
                    filter: false,
                    type: "number",
                    sort: true,
                    class: null,
                    value: null,
                    visible: true
                },
                //{
                //    label: "Kostpris pr. kg. u/moms",
                //    name: "costKG",
                //    filter: false,
                //    type: "number",
                //    sort: true,
                //    class: null,
                //    value: null,
                //    visible: true
                //},
                {
                    label: "Indkøbspris",
                    name: "costPrice",
                    filter: false,
                    type: "number",
                    sort: true,
                    class: null,
                    value: null,
                    visible: true
                },
                {

                    "name": "type",
                    "type": "select",
                    "label": "Type",
                    "value": null,
                    "filter": true,
                    "visible": true,
                    "sort": false,
                    "class": null,
                    "values": [
                        "normal",
                        "packet",
                        "discontinued"
                    ]
                },

                {
                    label: "Created",
                    name: "CreateDate",
                    filter: true,
                    type: "date",
                    sort: true,
                    class: null,
                    value: null,
                    visible: true
                },
                {
                    name: "edit",
                    visible: true,
                    label: "",
                    class: "text-right",
                }
            ],
            selected: [],
            loading: false
        }),
        components: {},
        computed: {},
        methods: {
            rowClick(e) {
                if (e.length > 0) {
                    //this.selected.push(e[0].Id);
                }
            },
        },
        created() {
        },
        mounted() { }
    };
</script>